import { FC } from "react";

import Button from "../../base/Button/Button";
import { ILink } from "../../base/Button/Button.map";
import Text from "../../base/Text/Text";
import Column from "../../layout/Column/Column";
import Container from "../../layout/Container/Container";
import Spacer from "../../layout/Spacer/Spacer";

export interface IErrorPageProps {
  title: string;
  body: string | string [];
  ctas?: ILink[];
  hideImage?: boolean;
}

const ErrorPage: FC<IErrorPageProps> = ({
  title,
  body,
  ctas,
  hideImage,
}) => (
  <>
    <div data-component={ErrorPage.name} className="bg-light-grey flex flex-1 w-full flex-col items-center min-h-screen">
      <Column justify="center" align="center" className="flex-1 text-center">
        { !hideImage && <img
          width={435}
          height={435}
          src="/images/error-cat.webp"
          loading="lazy"
          className="mt-4 max-h-[20vh] md:max-h-[30vh] object-contain"
          alt="disappointed cat"
        /> }
        <Text data-testid="error-title" tag="h1" display="title" align="center" size={{ default: "3xl", lg: "4xl" }}>
          { title }
        </Text>
        <Container className="mt-6 text-sm md:text-lg font-display">
          {
            Array.isArray(body) ? (
              <p >
                {
                  body.map((line, index) => <span key={line} data-testid={`line-${index}`} >
                    { line } { index !== body.length && (<br/>) }
                  </span>)
                }
              </p>
            ) : (<> { body } </>)
          }
        </Container>

        { ctas?.length && (
          <>
            <Spacer size="lg" />
            <Column justify="center" align="center" direction="row">
              { ctas.map((cta) => (
                <Button
                  key={cta.id}
                  to={cta.to}
                  color={cta.color}
                  design={cta.design}
                  data-testid={cta.testId}
                  className="mx-2"
                >
                  { cta.name }
                </Button>
              )) }

            </Column>
          </>
        ) }
      </Column>
      <div className="flex-0 md:flex-1" />
    </div>
  </>
);

export default ErrorPage;
